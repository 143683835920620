<template>
 <div class="content">
			<div class="content_inner">
				<div class="content_1">
					<div class="rank_list f_l">
            <h2>热门<a href="https://www.dongjizupai.com/">色情游戏</a></h2>
						<p class="con_tit"><span id="year">2021</span>热门游戏排行榜</p>
						<dl>
							<dt>
								<span class="m1">排名</span>
								<span class="m2">游戏</span>
								<span class="m3">状态</span>
								<span class="m4">新服</span>
							</dt>
							<dd class="clearfix">
								
								
								<a href="//yydw.91yxbox.com/on/bzsc/cnm156p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=cnm156p&amp;pos=1&amp;index=1" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="1" tmpl-name="cnm156p" data-img="">
									<span class="m1"><em class="num n1">1</em></span>
									<span class="m2">回收单职业</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/asbz/cnm201/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=cnm201&amp;pos=1&amp;index=2" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="2" tmpl-name="cnm201" data-img="">
									<span class="m1"><em class="num n2">2</em></span>
									<span class="m2">刀刀神装</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/bzsc/cnm200/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=cnm200&amp;pos=1&amp;index=3" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="3" tmpl-name="cnm200" data-img="">
									<span class="m1"><em class="num n3">3</em></span>
									<span class="m2">开局带毒狗</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/ahdts/wangyou175p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=wangyou175p&amp;pos=1&amp;index=4" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="4" tmpl-name="wangyou175p" data-img="">
									<span class="m1"><em class="num n4">4</em></span>
									<span class="m2">经典魔幻</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/bzsc/cnm203/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=cnm203&amp;pos=1&amp;index=5" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="5" tmpl-name="cnm203" data-img="">
									<span class="m1"><em class="num n5">5</em></span>
									<span class="m2">SSS级掉落</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/bfssqj/bfssqj3p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=bfssqj3p&amp;pos=1&amp;index=6" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="6" tmpl-name="bfssqj3p" data-img="">
									<span class="m1"><em class="num n6">6</em></span>
									<span class="m2">三国战略版</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/tmsh/xianxia64p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=xianxia64p&amp;pos=1&amp;index=7" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="7" tmpl-name="xianxia64p" data-img="">
									<span class="m1"><em class="num n7">7</em></span>
									<span class="m2">开局选地藏</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								<a href="//yydw.91yxbox.com/on/bzsc/shj13/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=&amp;open_creative_id=shj13&amp;pos=1&amp;index=8" game="lanyue" target="_blank" class="on modify_url" data-pos="1" data-index="8" tmpl-name="shj13" data-img="">
									<span class="m1"><em class="num n8">8</em></span>
									<span class="m2">山海异兽图鉴</span>
									<span class="m3"><em class="huo"></em></span>
									<span class="m4"><em class="enter">进入</em></span>
								</a>
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
							</dd>
						</dl>
					</div>

					<div class="hot_game f_r">
						<div class="con_tit">火爆游戏</div>
						<div class="clearfix">
							
							
							<dl>
								<dt>
									<a href="//yydw.91yxbox.com/on/bzsc/bxcnm5/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.abc97d3c6dda88bb078d6e4b67a4896c.png&amp;open_creative_id=bxcnm5&amp;pos=2&amp;index=1" target="_blank" class="modify_url" data-pos="2" data-index="1" tmpl-name="bxcnm5" data-img="/upload.abc97d3c6dda88bb078d6e4b67a4896c.png">
										<img alt="" src="../assets/upload.abc97d3c6dda88bb078d6e4b67a4896c.png" class="animated">
									</a>
									<div class="tag2"></div>
								</dt>
								<dd>
									<div class="tit">冰雪神器</div>
									<div class="txt">双端互通 冰雪二合一<br>真·免费 真·回收</div>
									<a href="//yydw.91yxbox.com/on/bzsc/bxcnm5/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.abc97d3c6dda88bb078d6e4b67a4896c.png&amp;open_creative_id=bxcnm5&amp;pos=2&amp;index=1" target="_blank" class="btn modify_url" data-pos="2" data-index="1" tmpl-name="bxcnm5" data-img="/upload.abc97d3c6dda88bb078d6e4b67a4896c.png"><i>开始游戏</i></a>
								</dd>
							</dl>
							
							
							
							<dl>
								<dt>
									<a href="//yydw.91yxbox.com/on/tmsh/tmsh1/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.829a2298215f6adce1a20a1e355db07b.png&amp;open_creative_id=tmsh1&amp;pos=2&amp;index=2" target="_blank" class="modify_url" data-pos="2" data-index="2" tmpl-name="tmsh1" data-img="/upload.829a2298215f6adce1a20a1e355db07b.png">
										<img alt="" src="../assets/upload.829a2298215f6adce1a20a1e355db07b.png" class="animated">
									</a>
									<div class="tag2"></div>
								</dt>
								<dd>
									<div class="tit">天命神话</div>
									<div class="txt">3D仙侠<br>鸿蒙初辟 万象始生</div>
									<a href="//yydw.91yxbox.com/on/tmsh/tmsh1/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.829a2298215f6adce1a20a1e355db07b.png&amp;open_creative_id=tmsh1&amp;pos=2&amp;index=2" target="_blank" class="btn modify_url" data-pos="2" data-index="2" tmpl-name="tmsh1" data-img="/upload.829a2298215f6adce1a20a1e355db07b.png"><i>开始游戏</i></a>
								</dd>
							</dl>
							
							
							
							<dl>
								<dt>
									<a href="//yydw.91yxbox.com/on/bfssqj/sg85/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.ce95e9b54d422298fcf403e748d585aa.png&amp;open_creative_id=sg85&amp;pos=2&amp;index=3" target="_blank" class="modify_url" data-pos="2" data-index="3" tmpl-name="sg85" data-img="/upload.ce95e9b54d422298fcf403e748d585aa.png">
										<img alt="" src="../assets/upload.ce95e9b54d422298fcf403e748d585aa.png" class="animated">
									</a>
									<div class="tag"></div>
								</dt>
								<dd>
									<div class="tit">兵法三十七计</div>
									<div class="txt">战争策略类页游<br>排兵布阵 争霸三国</div>
									<a href="//yydw.91yxbox.com/on/bfssqj/sg85/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.ce95e9b54d422298fcf403e748d585aa.png&amp;open_creative_id=sg85&amp;pos=2&amp;index=3" target="_blank" class="btn modify_url" data-pos="2" data-index="3" tmpl-name="sg85" data-img="/upload.ce95e9b54d422298fcf403e748d585aa.png"><i>开始游戏</i></a>
								</dd>
							</dl>
							
							
							
							<dl>
								<dt>
									<a href="//yydw.91yxbox.com/on/ahdts/ahdts9p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/yeyou/dt3/images/b_ahdts.png&amp;open_creative_id=ahdts9p&amp;pos=2&amp;index=4" target="_blank" class="modify_url" data-pos="2" data-index="4" tmpl-name="ahdts9p" data-img="http://file.7youxi.com/yeyou/dt3/images/b_ahdts.png">
										<img alt="" src="http://file.7youxi.com/yeyou/dt3/images/b_ahdts.png" class="animated">
									</a>
									<div class="tag"></div>
								</dt>
								<dd>
									<div class="tit">暗黑大天使</div>
									<div class="txt">全新奇迹.载誉归来<br>大天使之剑经典延续</div>
									<a href="//yydw.91yxbox.com/on/ahdts/ahdts9p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/yeyou/dt3/images/b_ahdts.png&amp;open_creative_id=ahdts9p&amp;pos=2&amp;index=4" target="_blank" class="btn modify_url" data-pos="2" data-index="4" tmpl-name="ahdts9p" data-img="http://file.7youxi.com/yeyou/dt3/images/b_ahdts.png"><i>开始游戏</i></a>
								</dd>
							</dl>
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
							
						</div>
					</div>
				</div>

				<div class="content_2">
					<ul class="game_list">
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/bzsc/cnm200/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.765b8c2d1e900d1a83d22ba5b28177b2.png&amp;open_creative_id=cnm200&amp;pos=3&amp;index=1" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="1" tmpl-name="cnm200" data-img="http://upload2.chenml.work/upload.765b8c2d1e900d1a83d22ba5b28177b2.png">
								<img src="http://upload2.chenml.work/upload.765b8c2d1e900d1a83d22ba5b28177b2.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">绿毒裁决</div>
								<div class="discription">打怪升级VIP</div>
								<a href="//yydw.91yxbox.com/on/bzsc/cnm200/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.765b8c2d1e900d1a83d22ba5b28177b2.png&amp;open_creative_id=cnm200&amp;pos=3&amp;index=1" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="1" tmpl-name="cnm200" data-img="http://upload2.chenml.work/upload.765b8c2d1e900d1a83d22ba5b28177b2.png"></a>
							</div>
						</li>
						
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/asbz/cnm181p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.699d3e95b89d272fbe2795f66bd56d4b.png&amp;open_creative_id=cnm181p&amp;pos=3&amp;index=2" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="2" tmpl-name="cnm181p" data-img="/upload.699d3e95b89d272fbe2795f66bd56d4b.png">
								<img src="../assets/upload.699d3e95b89d272fbe2795f66bd56d4b.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">金牛高爆版</div>
								<div class="discription">神装爆回家</div>
								<a href="//yydw.91yxbox.com/on/asbz/cnm181p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.699d3e95b89d272fbe2795f66bd56d4b.png&amp;open_creative_id=cnm181p&amp;pos=3&amp;index=2" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="2" tmpl-name="cnm181p" data-img="/upload.699d3e95b89d272fbe2795f66bd56d4b.png"></a>
							</div>
						</li>
						
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/bzsc/cnm154p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.b89bfa966bd4702b32fa4d7e7e98e287.png&amp;open_creative_id=cnm154p&amp;pos=3&amp;index=3" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="3" tmpl-name="cnm154p" data-img="/upload.b89bfa966bd4702b32fa4d7e7e98e287.png">
								<img src="../assets/upload.b89bfa966bd4702b32fa4d7e7e98e287.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">七彩神装</div>
								<div class="discription">即时战斗</div>
								<a href="//yydw.91yxbox.com/on/bzsc/cnm154p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.b89bfa966bd4702b32fa4d7e7e98e287.png&amp;open_creative_id=cnm154p&amp;pos=3&amp;index=3" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="3" tmpl-name="cnm154p" data-img="/upload.b89bfa966bd4702b32fa4d7e7e98e287.png"></a>
							</div>
						</li>
						
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/ahdts/wangyou186/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.aa80ceca0fe78a0b85cc71f13dbf5ac7.png&amp;open_creative_id=wangyou186&amp;pos=3&amp;index=4" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="4" tmpl-name="wangyou186" data-img="/upload.aa80ceca0fe78a0b85cc71f13dbf5ac7.png">
								<img src="../assets/upload.aa80ceca0fe78a0b85cc71f13dbf5ac7.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">4D新暗黑</div>
								<div class="discription">重温打怪掉宝</div>
								<a href="//yydw.91yxbox.com/on/ahdts/wangyou186/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.aa80ceca0fe78a0b85cc71f13dbf5ac7.png&amp;open_creative_id=wangyou186&amp;pos=3&amp;index=4" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="4" tmpl-name="wangyou186" data-img="/upload.aa80ceca0fe78a0b85cc71f13dbf5ac7.png"></a>
							</div>
						</li>
						
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/bfssqj/sg30p2/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.144c173aec9106659311e0d27414b6be.png&amp;open_creative_id=sg30p2&amp;pos=3&amp;index=5" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="5" tmpl-name="sg30p2" data-img="/upload.144c173aec9106659311e0d27414b6be.png">
								<img src="../assets/upload.144c173aec9106659311e0d27414b6be.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">三国武将传</div>
								<div class="discription">武将限时领</div>
								<a href="//yydw.91yxbox.com/on/bfssqj/sg30p2/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.144c173aec9106659311e0d27414b6be.png&amp;open_creative_id=sg30p2&amp;pos=3&amp;index=5" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="5" tmpl-name="sg30p2" data-img="/upload.144c173aec9106659311e0d27414b6be.png"></a>
							</div>
						</li>
						
						
						
						<li class="item">
							<a href="//yydw.91yxbox.com/on/tmsh/shj14/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.af0981368367c8cf46c7351b60a47760.png&amp;open_creative_id=shj14&amp;pos=3&amp;index=6" class="modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="6" tmpl-name="shj14" data-img="/upload.af0981368367c8cf46c7351b60a47760.png">
								<img src="../assets/upload.af0981368367c8cf46c7351b60a47760.png" alt="">
							</a>
							<div class="game_info">
								<div class="game_name">养龙传说</div>
								<div class="discription">唯美仙侠</div>
								<a href="//yydw.91yxbox.com/on/tmsh/shj14/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.af0981368367c8cf46c7351b60a47760.png&amp;open_creative_id=shj14&amp;pos=3&amp;index=6" class="enter_game modify_url" target="_blank" alt="礼包领取" data-pos="3" data-index="6" tmpl-name="shj14" data-img="/upload.af0981368367c8cf46c7351b60a47760.png"></a>
							</div>
						</li>
					</ul>
				</div>

				<div class="main clearfix">
				<div class="m_l f_l">
					<p class="con_tit">实时页游新区</p>
					<div class="new_ser">
						
						
						<a href="//yydw.91yxbox.com/on/bzsc/cnm135p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.89fca7ce61d711fdf075194c48908d42.png&amp;open_creative_id=cnm135p&amp;pos=4&amp;index=1" target="_blank" class="m3 modify_url" data-pos="4" data-index="1" tmpl-name="cnm135p" data-img="http://upload2.chenml.work/upload.89fca7ce61d711fdf075194c48908d42.png">
							<span class="pic f_l"><img alt="" class="animated" src="http://upload2.chenml.work/upload.89fca7ce61d711fdf075194c48908d42.png"></span>
							<span class="txt f_l">
								<p>回收高爆版</p>
								<span class="date">12-3</span>
								<span class="curr_new_server">&nbsp;&nbsp;今日新服</span>
							</span>
							<span class="btn">进入新服</span>
						</a>
						
						
						
						<a href="//yydw.91yxbox.com/on/asbz/cnm174p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.e5f7848621f1a12675f385b663cac7ae.png&amp;open_creative_id=cnm174p&amp;pos=4&amp;index=2" target="_blank" class="m3 modify_url" data-pos="4" data-index="2" tmpl-name="cnm174p" data-img="/upload.e5f7848621f1a12675f385b663cac7ae.png">
							<span class="pic f_l"><img alt="" class="animated" src="../assets/upload.e5f7848621f1a12675f385b663cac7ae.png"></span>
							<span class="txt f_l">
								<p>悟空打宝</p>
								<span class="date">12-3</span>
								<span class="curr_new_server">&nbsp;&nbsp;今日新服</span>
							</span>
							<span class="btn">进入新服</span>
						</a>
						
						
						
						<a href="//yydw.91yxbox.com/on/bfssqj/sg86/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.e89207762080ae24b0aa8cf0fdbed59b.png&amp;open_creative_id=sg86&amp;pos=4&amp;index=3" target="_blank" class="m3 modify_url" data-pos="4" data-index="3" tmpl-name="sg86" data-img="/upload.e89207762080ae24b0aa8cf0fdbed59b.png">
							<span class="pic f_l"><img alt="" class="animated" src="../assets/upload.e89207762080ae24b0aa8cf0fdbed59b.png"></span>
							<span class="txt f_l">
								<p>三国战役版</p>
								<span class="date">12-3</span>
								<span class="curr_new_server">&nbsp;&nbsp;今日新服</span>
							</span>
							<span class="btn">进入新服</span>
						</a>
						
						
						
						<a href="//yydw.91yxbox.com/on/ahdts/wangyou186/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.96dc179d91a47912d8e795cc011910bf.png&amp;open_creative_id=wangyou186&amp;pos=4&amp;index=4" target="_blank" class="m3 modify_url" data-pos="4" data-index="4" tmpl-name="wangyou186" data-img="/upload.96dc179d91a47912d8e795cc011910bf.png">
							<span class="pic f_l"><img alt="" class="animated" src="../assets/upload.96dc179d91a47912d8e795cc011910bf.png"></span>
							<span class="txt f_l">
								<p>神魔对决</p>
								<span class="date">12-3</span>
								<span class="curr_new_server">&nbsp;&nbsp;今日新服</span>
							</span>
							<span class="btn">进入新服</span>
						</a>
						
						
						
						<a href="//yydw.91yxbox.com/on/tmsh/xianxia64p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.181ab59d12a2f10ce7b84022a41f41bf.png&amp;open_creative_id=xianxia64p&amp;pos=4&amp;index=5" target="_blank" class="m3 modify_url" data-pos="4" data-index="5" tmpl-name="xianxia64p" data-img="/upload.181ab59d12a2f10ce7b84022a41f41bf.png">
							<span class="pic f_l"><img alt="" class="animated" src="../assets/upload.181ab59d12a2f10ce7b84022a41f41bf.png"></span>
							<span class="txt f_l">
								<p>梦幻修仙</p>
								<span class="date">12-3</span>
								<span class="curr_new_server">&nbsp;&nbsp;今日新服</span>
							</span>
							<span class="btn">进入新服</span>
						</a>
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
					</div>
					<div class="left_pic">
						<div class="tit">猜你喜欢</div>
						<div class="h5_pc_game">
							
							
							<a href="//yydw.91yxbox.com/on/bzsc/cnm198/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.7397fcaeb832c2030482f28afe931deb.jpg&amp;open_creative_id=cnm198&amp;pos=6&amp;index=1" target="_blank" class="pic f_l modify_url" data-pos="6" data-index="1" tmpl-name="cnm198" data-img="/upload.7397fcaeb832c2030482f28afe931deb.jpg">
								<img alt="" src="../assets/upload.7397fcaeb832c2030482f28afe931deb.jpg">
								
								<!-- <div class="offset_box">
									<div class="mask"></div>
									<div class="info">
										<img src="//file.7youxi.com/yeyou/dt3/images/dt3_tmld1.png">
										<div class="txt1"><p>扫码下载</p></div>
										<div class="txt2"><p>2M微端,手机电脑同步玩</p></div>
									</div>
								</div> -->
							</a>
							<dl class="txt f_r">
								<dt>道士n条狗<p></p></dt>
								<dd>
									<div>类别：打怪刷宝</div>
									<div>说明：全新版本,全新装备,全新玩法</div>
									<a href="//yydw.91yxbox.com/on/bzsc/cnm198/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.7397fcaeb832c2030482f28afe931deb.jpg&amp;open_creative_id=cnm198&amp;pos=6&amp;index=1" target="_blank" class="btn modify_url" data-pos="6" data-index="1" tmpl-name="cnm198" data-img="/upload.7397fcaeb832c2030482f28afe931deb.jpg"><em>开始游戏</em></a>
								</dd>
							</dl>
							
							
						</div>
					</div>
				</div>
				
				<div class="m_r f_r">
					<p class="con_tit">精彩游戏</p>
					<ul class="recom clearfix">
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/bzsc/cnm152p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.8c294c5ff4da27b077607047b457d090.png&amp;open_creative_id=cnm152p&amp;pos=5&amp;index=1" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="1" tmpl-name="cnm152p" data-img="/upload.8c294c5ff4da27b077607047b457d090.png">
								<img alt="" class="animated" src="../assets/upload.8c294c5ff4da27b077607047b457d090.png">
							</a>
							<dl class="txt f_r">
								<dt>回收2021<p></p></dt>
								<dd>
									<div>真·免费 真·回收</div>
									<a href="//yydw.91yxbox.com/on/bzsc/cnm152p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.8c294c5ff4da27b077607047b457d090.png&amp;open_creative_id=cnm152p&amp;pos=5&amp;index=1" target="_blank" class="btn modify_url" data-pos="5" data-index="1" tmpl-name="cnm152p" data-img="/upload.8c294c5ff4da27b077607047b457d090.png"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/bfssqj/sg79p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.efbccf4abae6012a7807f96f5c46723a.jpg&amp;open_creative_id=sg79p&amp;pos=5&amp;index=2" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="2" tmpl-name="sg79p" data-img="/upload.efbccf4abae6012a7807f96f5c46723a.jpg">
								<img alt="" class="animated" src="../assets/upload.efbccf4abae6012a7807f96f5c46723a.jpg">
							</a>
							<dl class="txt f_r">
								<dt>三国网页版<p></p></dt>
								<dd>
									<div>一统乱世江山</div>
									<a href="//yydw.91yxbox.com/on/bfssqj/sg79p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.efbccf4abae6012a7807f96f5c46723a.jpg&amp;open_creative_id=sg79p&amp;pos=5&amp;index=2" target="_blank" class="btn modify_url" data-pos="5" data-index="2" tmpl-name="sg79p" data-img="/upload.efbccf4abae6012a7807f96f5c46723a.jpg"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/tmsh/xianxia69/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.0097ab57551f8ab67623902f344b155f.png&amp;open_creative_id=xianxia69&amp;pos=5&amp;index=3" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="3" tmpl-name="xianxia69" data-img="/upload.0097ab57551f8ab67623902f344b155f.png">
								<img alt="" class="animated" src="../assets/upload.0097ab57551f8ab67623902f344b155f.png">
							</a>
							<dl class="txt f_r">
								<dt>神凰录<p></p></dt>
								<dd>
									<div>3D神话动作网游</div>
									<a href="//yydw.91yxbox.com/on/tmsh/xianxia69/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.0097ab57551f8ab67623902f344b155f.png&amp;open_creative_id=xianxia69&amp;pos=5&amp;index=3" target="_blank" class="btn modify_url" data-pos="5" data-index="3" tmpl-name="xianxia69" data-img="/upload.0097ab57551f8ab67623902f344b155f.png"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/asbz/asbz1/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.d756e32ae8378ea5cbac6c90c1b19c05.png&amp;open_creative_id=asbz1&amp;pos=5&amp;index=4" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="4" tmpl-name="asbz1" data-img="/upload.d756e32ae8378ea5cbac6c90c1b19c05.png">
								<img alt="" class="animated" src="../assets/upload.d756e32ae8378ea5cbac6c90c1b19c05.png">
							</a>
							<dl class="txt f_r">
								<dt>威名震四方<p></p></dt>
								<dd>
									<div>西方魔幻RPG</div>
									<a href="//yydw.91yxbox.com/on/asbz/asbz1/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.d756e32ae8378ea5cbac6c90c1b19c05.png&amp;open_creative_id=asbz1&amp;pos=5&amp;index=4" target="_blank" class="btn modify_url" data-pos="5" data-index="4" tmpl-name="asbz1" data-img="/upload.d756e32ae8378ea5cbac6c90c1b19c05.png"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/tmsh/tmsh2/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.1c16dd542a3de4f87e08a93271c55e39.png&amp;open_creative_id=tmsh2&amp;pos=5&amp;index=5" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="5" tmpl-name="tmsh2" data-img="/upload.1c16dd542a3de4f87e08a93271c55e39.png">
								<img alt="" class="animated" src="../assets/upload.1c16dd542a3de4f87e08a93271c55e39.png">
							</a>
							<dl class="txt f_r">
								<dt>天命神话<p></p></dt>
								<dd>
									<div>唯美仙侠游戏</div>
									<a href="//yydw.91yxbox.com/on/tmsh/tmsh2/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.1c16dd542a3de4f87e08a93271c55e39.png&amp;open_creative_id=tmsh2&amp;pos=5&amp;index=5" target="_blank" class="btn modify_url" data-pos="5" data-index="5" tmpl-name="tmsh2" data-img="/upload.1c16dd542a3de4f87e08a93271c55e39.png"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						<li>
							<a href="//yydw.91yxbox.com/on/ahdts/wangyou179p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.0ee142b552cec903f60a566d367ca187.png&amp;open_creative_id=wangyou179p&amp;pos=5&amp;index=6" target="_blank" class="pic f_l modify_url" data-pos="5" data-index="6" tmpl-name="wangyou179p" data-img="/upload.0ee142b552cec903f60a566d367ca187.png">
								<img alt="" class="animated" src="../assets/upload.0ee142b552cec903f60a566d367ca187.png">
							</a>
							<dl class="txt f_r">
								<dt> 暗黑幻境<p></p></dt>
								<dd>
									<div> 全新西方魔幻</div>
									<a href="//yydw.91yxbox.com/on/ahdts/wangyou179p/b2a/ccid82293/?fy=off&amp;creative_id=1796&amp;bd_vid=11264782131946173041&amp;img_name=/upload.0ee142b552cec903f60a566d367ca187.png&amp;open_creative_id=wangyou179p&amp;pos=5&amp;index=6" target="_blank" class="btn modify_url" data-pos="5" data-index="6" tmpl-name="wangyou179p" data-img="/upload.0ee142b552cec903f60a566d367ca187.png"><em>开始游戏</em></a>
								</dd>
							</dl>
						</li>
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
						
					</ul>
				</div>
			</div>
			</div>
		</div> 
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
